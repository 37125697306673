import {
  ButtonGroup,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import { LAYOUTMODES, useLayoutMode } from "../hooks/useLayoutMode";
import { useColorsContext } from "@buildsoft-eu/react";
import MergeToolContent from "./MergeToolContent";

export default function MergeTool({
  isOpen,
  onClose,
  contentToMerge,
  mergedValues,
  setMergedValues,
  setTakeMergedValues,
  setTakeServer,
  t,
}) {
  var { mode } = useLayoutMode();
  const modalSize = mode === LAYOUTMODES.MOBILE ? "md" : "6xl";
  const {
    modal: { footer: footerColors },
  } = useColorsContext();
  const bodyPadding = mode === LAYOUTMODES.DESKTOP ? "12" : "2";
  const mergeButtonIsDisabled = () => {
    return contentToMerge.length !== mergedValues.length ? true : false;
  };

  return (
    <Modal
      size={modalSize}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay opacity="0.5" />
      <ModalContent borderRadius="md" borderTop="4px" borderColor="orange.500">
        <ModalBody mb="0" pb="0" pl={bodyPadding} pr={bodyPadding}>
          {contentToMerge.map((conflict) => {
            return (
              <MergeToolContent
                key={conflict.key}
                conflict={conflict}
                mergedValues={mergedValues}
                setMergedValues={setMergedValues}
                t={t}
              />
            );
          })}
        </ModalBody>
        <ModalFooter
          mt="4"
          bg={footerColors.background}
          borderBottomRadius="md"
        >
          <ButtonGroup spacing="8">
            <Button
              variant="unstyled"
              onClick={() => {
                setTakeServer(true);
                onClose();
              }}
            >
              {t("mergeTool.takeServer")}
            </Button>
            <Button
              isDisabled={mergeButtonIsDisabled()}
              loadingText={t("mergeTool.acceptMerge")}
              spinnerPlacement="end"
              _hover={{ bgColor: "orange.500" }}
              bgColor="orange.500"
              textColor="orange.100"
              onClick={async () => {
                setTakeMergedValues(true);
                onClose();
              }}
            >
              {t("mergeTool.acceptMerge")}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
