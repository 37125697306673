import {
  Avatar,
  Box,
  ButtonGroup,
  Button,
  Center,
  Flex,
  Text,
  IconButton,
  Spacer,
  SimpleGrid,
  VStack,
  useDisclosure,
  useToast,
  HStack,
} from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useColorsContext,
  useDisplayPreferencesContext,
  Input,
  Upload,
  UPLOAD_ERRORCODES,
} from "@buildsoft-eu/react";
import { useRef } from "react";
import {
  OrganizationByIdUrl,
  OrganizationInvitationsUrl,
  OrganizationInvitationByIdUrl,
  OrganizationInvitationRenewUrl,
  BlobsImagesUrl,
} from "../../services/urlService";
import { useTranslation } from "react-i18next";
import { useOrganizationDetailsPage } from "../../hooks/useOrganizationDetailsPage";
import { useIdentityContext } from "../../contexts/IdentityContext";
import { useParams, useNavigate } from "react-router-dom";
import { FaRegTrashAlt } from "react-icons/fa";
import { useRequestIsLoading } from "../../hooks/useRequestIsLoading";
import {
  ORGANIZATIONPROPERTIES,
  organizationsTab,
} from "../../services/PagesService";
import { OrganizationRoles, ROLES } from "../../services/memberRolesService";
import { HiOutlinePlus } from "react-icons/hi";
import {
  canUpdateOrganizationInformation,
  canDeleteOrganization,
  canCreateTeam,
  canSeeInvitations,
} from "../../services/rulesService";
import { LAYOUTMODES, useLayoutMode } from "../../hooks/useLayoutMode";
import { IoMdBusiness } from "react-icons/io";
import { FaChrome } from "react-icons/fa";
import { ImEarth } from "react-icons/im";
import { useUploadPicture } from "../../hooks/useUploadPicture";
import { fileName } from "../../services/uploadImageService";
import { sortMembers } from "../../services/sortingService";
import { useAxios } from "../../hooks/useAxios";
import NotFoundPage from "./NotFoundPage";
import LoadingPage from "./LoadingPage";
import ConfirmDeletionModal from "../ConfirmDeletionModal";
import OrganizationMembers from "../OrganizationMembers";
import Invitations from "../Invitations";
import CreateTeamModal from "../CreateTeamModal";
import TeamInformation from "../TeamInformation";
import MergeTool from "../MergeTool";
import NotifyEntityDeletionModal from "../NotifyEntityDeletionModal";
import { MERGEACTION } from "../../services/mergeToolService";
import { USERCLAIMS } from "../../services/PagesService";

export default function OrganizationDetailsPage({ minH }) {
  const { user } = useIdentityContext();
  const { organizationId } = useParams();
  const { getRequest, deleteRequest } = useAxios();
  const { getAccessTokenSilently } = useAuth0();
  const {
    locale: { toDisplayDate },
  } = useDisplayPreferencesContext();
  const toast = useToast();
  const { requestIsLoading, setRequestIsLoading } = useRequestIsLoading();
  const {
    blobUrl,
    setBlobUrl,
    uploadProcessFinished,
    setUploadProcessFinished,
    uploadPicture,
    file,
    setFile,
  } = useUploadPicture(getAccessTokenSilently, toast, setRequestIsLoading);
  const toastIdRef = useRef();
  const closeToast = () => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  };
  const showError = (errorMessage) => {
    toast({
      title: errorMessage,
      status: "error",
      isClosable: true,
    });
  };
  const addNotificationButtons = () => {
    return (
      <HStack w="100%" justify="end">
        <ButtonGroup spacing="8">
          <Button
            size="sm"
            variant="unstyled"
            onClick={() => {
              setNotificationAction(MERGEACTION.TAKESERVER);
              closeToast();
            }}
          >
            {t("mergeTool.takeServer")}
          </Button>
          <Button
            size="sm"
            variant="unstyled"
            loadingText={t("mergeTool.compareChanges")}
            onClick={() => {
              setNotificationAction(MERGEACTION.DISPLAYMERGETOOL);
              closeToast();
            }}
          >
            {t("mergeTool.compareChanges")}
          </Button>
        </ButtonGroup>
      </HStack>
    );
  };
  const notificationOrganizationUpdated = (notificationMessage) => {
    if (!toast.isActive("notificationToast")) {
      toastIdRef.current = toast({
        title: notificationMessage,
        description: addNotificationButtons(),
        id: "notificationToast",
        isClosable: false,
        duration: null,
        position: "bottom",
        variant: ["customNotification"],
      });
    }
  };
  const { t } = useTranslation();
  const {
    isOpen: isOpenMergeTool,
    onOpen: onOpenMergeTool,
    onClose: onCloseMergeTool,
  } = useDisclosure();
  const {
    isOpen: isOpenNotifyDeletion,
    onOpen: onOpenNotifyDeletion,
    onClose: onCloseNotifyDeletion,
  } = useDisclosure();
  const goToOrganizations = () =>
    navigate("/", { replace: true, state: { tab: organizationsTab } });
  const {
    organization,
    setOrganization,
    organizationName,
    setOrganizationName,
    organizationPicture,
    setOrganizationPicture,
    organizationWebsite,
    setOrganizationWebsite,
    organizationUpdates,
    setOrganizationUpdates,
    pageIsLoading,
    invitations,
    setInvitations,
    teams,
    setTeams,
    etag,
    setEtag,
    pendingUploadPicture,
    setPendingUploadPicture,
    contentToMerge,
    mergedValues,
    setMergedValues,
    setTakeMergedValues,
    setTakeServer,
    organizationInServer,
    setNotificationAction,
    countDown,
    deletionMessageModal,
    userRole,
  } = useOrganizationDetailsPage(
    organizationId,
    getAccessTokenSilently,
    user,
    setRequestIsLoading,
    blobUrl,
    setBlobUrl,
    uploadProcessFinished,
    setUploadProcessFinished,
    showError,
    t,
    onOpenMergeTool,
    file,
    notificationOrganizationUpdated,
    onOpenNotifyDeletion,
    goToOrganizations
  );
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenCreateTeamModal,
    onOpen: onOpenCreateTeamModal,
    onClose: onCloseCreateTeamModal,
  } = useDisclosure();
  const { panel: panelColors } = useColorsContext();
  const gridStyleProps = {
    bg: panelColors.background,
    w: "100%",
    boxShadow: "lg",
    display: "flex",
    borderWidth: "1px",
    rounded: "2xl",
    p: "4",
    spacing: "3",
    direction: {
      base: "column",
      md: "row",
    },
    justify: { base: "center" },
    align: { base: "center", md: "center" },
  };
  var { mode } = useLayoutMode();

  const canUpdateOrganizationInfo = canUpdateOrganizationInformation(userRole);

  const discardChanges = () => {
    setOrganizationUpdates([]);
    setOrganizationName(organization?.displayName);
    setOrganizationPicture(organization?.picture);
    setOrganizationWebsite(organization?.website ?? "");
    setEtag(organization?.eTag);
  };

  const displayTeamsSection = () => {
    return teams.length === 0 && userRole === ROLES.guest ? false : true;
  };

  const displayTeams = (team) => {
    return (
      <TeamInformation
        key={team?.id}
        team={team}
        t={t}
        previousPage={"organizationDetails"}
        organizationName={organization?.displayName}
      />
    );
  };

  const updatePicture = () => {
    return (
      <Upload
        label={t(`userClaims.${USERCLAIMS.PICTURE}`)}
        message={t("uploadFiles.mainMessage")}
        description={t("uploadFiles.restrictionsMessage")}
        w={mode === LAYOUTMODES.MOBILE ? "100%" : "25%"}
        layout="column"
        noBorder
        avatarName={organization?.displayName}
        fileUrl={organizationPicture}
        fileName={file?.name}
        setFile={(newFile) => {
          setFile(newFile);
          const url = URL.createObjectURL(newFile);
          setOrganizationPicture(url);
          setPendingUploadPicture(true);
        }}
        canClearFile={organizationPicture?.length > 0}
        clearFile={() => {
          setFile(undefined);
          setOrganizationPicture("");
          setBlobUrl("");
          setPendingUploadPicture(false);
        }}
        showErrors={(errors) => {
          const error = errors[0];
          switch (error.code) {
            case UPLOAD_ERRORCODES.INVALIDTYPE:
              showError(t("errors.invalidType"));
              break;
            case UPLOAD_ERRORCODES.TOOMANY:
              showError(t("errors.tooMany"));
              break;
            case UPLOAD_ERRORCODES.NOTSQUARE:
              showError(t("errors.notSquare"));
              break;
            case UPLOAD_ERRORCODES.TOOLARGE:
              showError(t("errors.pictureDimensions"));
              break;
            default:
              showError(t("errors.pictureSize"));
              break;
          }
        }}
      />
    );
  };

  const displayOrganizationPicture = () => {
    return (
      <Center>
        <Avatar
          size={mode === LAYOUTMODES.DESKTOP ? "2xl" : "xl"}
          ml="10"
          mr="10"
          src={organizationPicture}
        />
      </Center>
    );
  };

  const addStateToOrganizationUpdates = (stateName) => {
    if (organizationUpdates.indexOf(stateName) === -1) {
      setOrganizationUpdates((prevState) => [...prevState, stateName]);
    }
  };

  const removeStateToOrganizationUpdates = (stateName) => {
    setOrganizationUpdates((prevState) =>
      prevState.filter((st) => st !== stateName)
    );
  };

  if (pageIsLoading) {
    return <LoadingPage minH={minH} />;
  }

  if (Object.keys(organization).length === 0) {
    return <NotFoundPage minH={minH} />;
  }

  return (
    <Box
      minH={minH}
      display="flex"
      alignItems="baseline"
      p="5"
      justifyContent="center"
    >
      <VStack minW={{ base: "92vw", lg: "90vw", xl: "80vw", "2xl": "75vw" }}>
        <VStack {...gridStyleProps}>
          <Box w="100%">
            <Flex w="100%" justifyContent="end" mb="0">
              <Text fontWeight="light" fontSize="sm" pt="2">
                {t("organizations.lastModified")}
                {": "}
                {toDisplayDate(organization?.lastModified)}
              </Text>
              <Spacer />
              {canDeleteOrganization(userRole) && (
                <IconButton
                  size="sm"
                  icon={<FaRegTrashAlt />}
                  onClick={onOpen}
                />
              )}
            </Flex>
            <ConfirmDeletionModal
              isOpen={isOpen}
              onClose={onClose}
              t={t}
              message={<Text>{t("organizations.confirmRemove")}</Text>}
              requestIsLoading={requestIsLoading}
              setRequestIsLoading={setRequestIsLoading}
              picture={organization.picture}
              name={organization.displayName}
              action={async () => {
                const getNewEtag = async () => {
                  const { response, isError } = await getRequest(
                    OrganizationByIdUrl(organizationId)
                  );
                  return isError ? null : response.data?.eTag;
                };
                const updateStates = () => {
                  setRequestIsLoading(false);
                  goToOrganizations();
                  onClose();
                };
                const displayError = () => {
                  showError(t("errors.deleteOrganization"));
                  setRequestIsLoading(false);
                  onClose();
                };
                setRequestIsLoading(true);

                const { response, isError } = await deleteRequest(
                  OrganizationByIdUrl(organization.id),
                  etag
                );
                if (!isError) {
                  updateStates();
                  return;
                }
                if (response.response?.status !== 409) {
                  displayError();
                  return;
                }
                const newEtag = await getNewEtag();
                if (!newEtag) {
                  return;
                }
                const { isError: isErrorConflict } = await deleteRequest(
                  OrganizationByIdUrl(organization.id),
                  newEtag
                );
                if (isErrorConflict) {
                  displayError();
                  return;
                }
                updateStates();
              }}
            />
          </Box>
          <Flex
            w="100%"
            gap="8"
            direction={{ base: "column", md: "row" }}
            mt="1"
          >
            {canUpdateOrganizationInfo
              ? updatePicture()
              : displayOrganizationPicture()}
            <VStack
              w={mode === LAYOUTMODES.MOBILE ? "100%" : "70%"}
              spacing="6"
            >
              <Input
                label={t("common.name")}
                value={organizationName}
                noBorder
                icon={IoMdBusiness}
                isReadOnly={!canUpdateOrganizationInfo}
                onChange={(newValue) => {
                  setOrganizationName(newValue);
                  const valueToCompare = organizationInServer
                    ? organizationInServer
                    : organization;
                  if (newValue === valueToCompare.displayName) {
                    removeStateToOrganizationUpdates(
                      ORGANIZATIONPROPERTIES.DISPLAYNAME
                    );
                    return;
                  }
                  addStateToOrganizationUpdates(
                    ORGANIZATIONPROPERTIES.DISPLAYNAME
                  );
                }}
              />
              <Input
                label={t("organizations.website")}
                value={organizationWebsite}
                noBorder
                icon={FaChrome}
                isReadOnly={!canUpdateOrganizationInfo}
                onChange={(newValue) => {
                  setOrganizationWebsite(newValue);
                  const valueToCompare = organizationInServer
                    ? organizationInServer
                    : organization;
                  if (newValue === valueToCompare.website) {
                    removeStateToOrganizationUpdates(
                      ORGANIZATIONPROPERTIES.WEBSITE
                    );
                    return;
                  }
                  addStateToOrganizationUpdates(ORGANIZATIONPROPERTIES.WEBSITE);
                }}
              />
              <Input
                label={t(`userClaims.${USERCLAIMS.COUNTRY}`)}
                value={t(`country.${organization?.country}`)}
                noBorder
                icon={ImEarth}
                isReadOnly
              />
            </VStack>
          </Flex>
          {canUpdateOrganizationInformation(userRole) && (
            <ButtonGroup
              variant="outline"
              spacing="3"
              w="100%"
              justifyContent="end"
              pr="5"
            >
              <Button
                isLoading={requestIsLoading}
                isDisabled={
                  requestIsLoading
                    ? requestIsLoading
                    : organizationUpdates.length > 0
                    ? false
                    : true
                }
                loadingText={t("common.save")}
                spinnerPlacement="end"
                colorScheme="red"
                onClick={async () => {
                  setRequestIsLoading(true);
                  closeToast();
                  if (organizationPicture === organization.picture) {
                    setUploadProcessFinished(true);
                    return;
                  }
                  if (!pendingUploadPicture) {
                    setUploadProcessFinished(true);
                    return;
                  }
                  setPendingUploadPicture(false);
                  let formData = new FormData();
                  formData.append(fileName, file);
                  await uploadPicture(
                    formData,
                    BlobsImagesUrl,
                    t("errors.updateOrganization"),
                    setOrganizationPicture
                  );
                }}
              >
                {t("common.save")}
              </Button>
              <Button
                isDisabled={
                  requestIsLoading
                    ? requestIsLoading
                    : organizationUpdates.length > 0
                    ? false
                    : true
                }
                onClick={() => discardChanges()}
              >
                {t("common.discard")}
              </Button>
            </ButtonGroup>
          )}
        </VStack>
        <OrganizationMembers
          user={user}
          organizationUserRole={userRole}
          organizationId={organizationId}
          members={sortMembers(organization.memberships)}
          setOrganization={setOrganization}
          roleList={OrganizationRoles}
          gridStyleProps={gridStyleProps}
          t={t}
          etag={etag}
          setEtag={setEtag}
        />
        {canSeeInvitations(userRole) && (
          <Invitations
            entityId={organizationId}
            createInvitationUrl={OrganizationInvitationsUrl}
            invitationByIdUrl={OrganizationInvitationByIdUrl}
            invitationRenewUrl={OrganizationInvitationRenewUrl}
            invitations={invitations}
            setInvitations={setInvitations}
            gridStyleProps={gridStyleProps}
            t={t}
          />
        )}
        {displayTeamsSection() && (
          <Box {...gridStyleProps}>
            <VStack w="100%">
              <Flex w="100%" justifyContent="end">
                <Text fontWeight="light" fontSize="sm" pt="2">
                  {t("organizations.teams")}
                </Text>
                <Spacer />
                {canCreateTeam(userRole) && (
                  <Button
                    leftIcon={<HiOutlinePlus />}
                    mb="2"
                    onClick={onOpenCreateTeamModal}
                    fontSize="md"
                    colorScheme="red"
                    variant="outline"
                  >
                    {t("teams.newTeam")}
                  </Button>
                )}
                <CreateTeamModal
                  isOpen={isOpenCreateTeamModal}
                  onClose={onCloseCreateTeamModal}
                  t={t}
                  setTeams={setTeams}
                  organizationId={organization.id}
                />
              </Flex>
              {teams.length > 0 && (
                <SimpleGrid
                  w="100%"
                  spacing="5"
                  columns={{ lg: 3, md: 2, sm: 1 }}
                >
                  {teams?.map(displayTeams)}
                </SimpleGrid>
              )}
            </VStack>
          </Box>
        )}
        <Box w="100%"></Box>
      </VStack>
      <MergeTool
        isOpen={isOpenMergeTool}
        onClose={onCloseMergeTool}
        contentToMerge={contentToMerge}
        t={t}
        mergedValues={mergedValues}
        setMergedValues={setMergedValues}
        setTakeMergedValues={setTakeMergedValues}
        setTakeServer={setTakeServer}
      />
      <NotifyEntityDeletionModal
        isOpen={isOpenNotifyDeletion}
        onClose={onCloseNotifyDeletion}
        message={`${t(deletionMessageModal)} ${countDown} ${t(
          "common.seconds"
        )}`}
        picture={organization?.picture}
        name={organization?.displayName}
      />
    </Box>
  );
}
